import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Equipment`}</h2>
    <p>{`Assume a character owns at least one outfit of normal clothes.
Pick any one of the following clothing
outfits: artisan`}{`’`}{`s outfit, entertainer`}{`’`}{`s outfit, explorer`}{`’`}{`s outfit,
monk`}{`’`}{`s outfit, peasant`}{`’`}{`s outfit, scholar`}{`’`}{`s outfit, or traveler`}{`’`}{`s outfit.`}</p>
    <h3>{`WEALTH AND MONEY`}</h3>
    <h5>{`COINS`}</h5>
    <p>{`The most common coin is the gold piece (gp). A
gold piece is worth 10 silver pieces. Each silver piece is worth 10
copper pieces (cp). In addition to copper, silver, and gold coins,
there are also platinum pieces (pp), which are each worth 10 gp.`}</p>
    <p>{`The standard coin weighs about a third of an ounce (fifty to
the pound).`}</p>
    <p><strong parentName="p">{`Table: Coins`}</strong></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "125px"
          }}>&nbsp;</th>
          <th style={{
            "width": "25px"
          }}>&nbsp;</th>
          <th colSpan="4" className="center">Exchange Value</th>
        </tr>
        <tr>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th style={{
            "width": "50px"
          }}>CP</th>
          <th style={{
            "width": "50px"
          }}>SP</th>
          <th style={{
            "width": "50px"
          }}>GP</th>
          <th style={{
            "width": "50px"
          }}>PP</th>
        </tr>
        <tr className="odd-row">
          <td>Copper piece (cp)</td>
          <td>=</td>
          <td>1</td>
          <td>1/10</td>
          <td>1/100</td>
          <td>1/1,000</td>
        </tr>
        <tr>
          <td>Silver piece (sp)</td>
          <td>=</td>
          <td>10</td>
          <td>1</td>
          <td>1/10</td>
          <td>1/100</td>
        </tr>
        <tr className="odd-row">
          <td>Gold piece (gp)</td>
          <td>=</td>
          <td>100</td>
          <td>10</td>
          <td>1</td>
          <td>1/10</td>
        </tr>
        <tr>
          <td className="last-row">Platinum piece (pp)</td>
          <td className="last-row">=</td>
          <td className="last-row">1,000</td>
          <td className="last-row">100</td>
          <td className="last-row">10</td>
          <td className="last-row">1</td>
        </tr>
      </tbody>
    </table>
    <h5>{`WEALTH OTHER THAN COINS`}</h5>
    <p>{`Merchants commonly exchange trade goods
without using currency. As a means of comparison, some trade goods are
detailed below.`}</p>
    <p><strong parentName="p">{`Table: Trade Goods`}</strong></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "75px"
          }}>Cost</th>
          <th>Item</th>
        </tr>
        <tr className="odd-row">
          <td>1 cp</td>
          <td>One pound of wheat</td>
        </tr>
        <tr>
          <td>2 cp</td>
          <td>One pound of flour, or one chicken</td>
        </tr>
        <tr className="odd-row">
          <td>1 sp</td>
          <td>One pound of iron</td>
        </tr>
        <tr>
          <td>5 sp</td>
          <td>One pound of tobacco or copper</td>
        </tr>
        <tr className="odd-row">
          <td>1 gp</td>
          <td>One pound of cinnamon, or one goat</td>
        </tr>
        <tr>
          <td>2 gp</td>
          <td>One pound of ginger or pepper, or one sheep</td>
        </tr>
        <tr className="odd-row">
          <td>3 gp</td>
          <td>One pig</td>
        </tr>
        <tr>
          <td>4 gp</td>
          <td>One square yard of linen</td>
        </tr>
        <tr className="odd-row">
          <td>5 gp</td>
          <td>One pound of salt or silver</td>
        </tr>
        <tr>
          <td>10 gp</td>
          <td>One square yard of silk, or one cow</td>
        </tr>
        <tr className="odd-row">
          <td>15 gp</td>
          <td>One pound of saffron or cloves, or one ox</td>
        </tr>
        <tr>
          <td>50 gp</td>
          <td>One pound of gold</td>
        </tr>
        <tr className="odd-row">
          <td>500 gp</td>
          <td>One pound of platinum</td>
        </tr>
      </tbody>
    </table>
    <h5>{`SELLING LOOT`}</h5>
    <p>{`In general, a character can sell something for
half its listed price.`}</p>
    <p>{`Trade goods are the exception to the half-price rule. A trade
good, in this sense, is a valuable good that can be easily exchanged
almost as if it were cash itself.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      